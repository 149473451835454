h1,
h2 {
  font-weight: 400;
  margin: 0 0 var(--spacer-2);
}

h1 {
  color: var(--color-secondary);
  font-family: var(--font-stack-heading);
  font-size: 3.4rem;
  line-height: 1;

  @media screen and (min-width: $bp-large) {
    font-size: 4.8rem;
  }
}

h2 {
  color: var(--color-tertiary);
  font-size: 1.6rem;
  line-height: 1.2;

  @media screen and (min-width: $bp-large) {
    font-size: 2.2rem;
  }
}

a {
  color: var(--color-tertiary);
  transition: color 300ms;

  &:hover {
    color: var(--color-secondary);
  }
}
