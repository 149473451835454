.site-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacer-3);
  position: sticky;
}

.site-logo {
  animation: slideInFromLeft 1s ease-in-out 1s backwards;
  min-width: 15vmin;
  width: 8vw;

  @media screen and (min-width: $bp-large) {
    margin-left: var(--spacer-4);
  }
}

.site-title {
  margin-bottom: var(--spacer-4);
}

.social-networks {
  animation: fadeIn 1s backwards;
}

.social-network {
  display: inline-block;
  margin-left: var(--spacer-2);
  min-width: 2rem;
  opacity: 0.5;
  transition: opacity 300ms, transform 300ms;
  width: 6vmin;

  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

.page {
  animation: fadeIn 1s backwards;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $bp-large) {
    width: 60vw;
  }
}
